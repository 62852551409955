<template>
  <v-app-bar 
    fixed
    height="73"
    color="#fff"
    elevation="0"
    style="border-bottom: 1px solid #ddd"
  >
    <v-toolbar-title>
      <v-btn 
        icon
        small
        class="mr-2 hidden-md-and-up"
        @click="$emit('drawer')"
      >
        <v-icon small>mdi-menu</v-icon>
      </v-btn>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <!-- <v-avatar
      size="38"
      color="primary"
    >
      <span class="white--text text-h5">

      </span>
    </v-avatar> -->

    <v-btn 
      icon
      small
      class="mr-2"
      color="red"
      @click="logout()"
    >
      <v-icon small>
        mdi-power-standby
      </v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  methods: {
    logout () {
      vault.seal()
      location.href = '/auth/login' 
    }
  }
}
</script>